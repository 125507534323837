import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { isFunction } from '../../../../helpers/Promise';
import PageTitle from './components/PageTitle';
import StatusMessageConsumer from './components/StatusMessageConsumer';
import ComponentLoader from '../../../ComponentLoader';

export default function PageContent({
  title,
  description,
  loading,
  loadingMessage,
  children,
  fullWidth,
  showTitle,
}) {
  if (isFunction(children)) return children({ PageTitle, StatusMessageConsumer });

  const pageContent = (
    <ComponentLoader loading={loading} message={loadingMessage}>
      <>
        <PageTitle title={title} description={description} showTitle={showTitle} />
        <StatusMessageConsumer />
        {children}
      </>
    </ComponentLoader>
  );

  return fullWidth ? (
    <div className="page-full-width">{pageContent}</div>
  ) : (
    <Container className="page-container">{pageContent}</Container>
  );
}

PageContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  fullWidth: PropTypes.bool,
  showTitle: PropTypes.bool,
};

PageContent.defaultProps = {
  title: undefined,
  description: undefined,
  children: null,
  loading: false,
  loadingMessage: undefined,
  fullWidth: false,
  showTitle: true,
};
