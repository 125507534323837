import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../Spinner';
import { isFunction } from '../../helpers/Promise';

export default function ComponentLoader({ loading, children, ...props }) {
  if (loading) return <Spinner {...props} />;

  return isFunction(children) ? children() : children;
}

ComponentLoader.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

ComponentLoader.defaultProps = {
  loading: false,
};
