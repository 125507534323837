import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Navbar, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import UserMenu from './components/UserMenu';
import LoginDialog from './components/LoginDialog';
import HeaderLogo from '../../../../assets/images/logo.png';
import * as routes from '../../../../routes';
import Link from '../../../Link';
import useUser from '../../../../hooks/useUser';
import useModal from '../../../../hooks/useModal';
import LanguageBar from './components/LanguageBar';
import Button from '../../../Form/components/Button';

import './header.css';

export default function Header() {
  const { isAuth } = useUser();
  const { t } = useTranslation();
  const { showModal, hideModal } = useModal();

  const userMenu = useMemo(
    () => ({
      home: {
        label: t('header.home'),
        route: routes.homeRoute,
      },
      quotes: {
        label: t('header.quotes'),
        route: routes.createPackagingRoute,
      },
      sales: {
        label: t('header.sales'),
        route: routes.viewSalesRoute,
      },
      aboutUs: {
        label: t('header.aboutUs'),
        route: routes.aboutUsRoute,
      },
      contacts: {
        label: t('header.contacts'),
        route: routes.contactsRoute,
      },
    }),
    [t]
  );

  const accountMenu = useMemo(
    () => ({
      profileMenu: {
        label: t('header.profile'),
        dropDown: [
          {
            label: t('header.editProfile'),
            route: routes.editProfileRoute,
          },
          {
            label: t('header.manageCases'),
            route: routes.manageCasesRoute,
          },
          {
            label: t('header.manageTrims'),
            route: routes.manageTrimsRoute,
          },
          {
            label: t('header.manageEnnobles'),
            route: routes.manageEnnoblesRoute,
          },
          {
            label: t('header.manageDieCuts'),
            route: routes.manageDieCutsRoute,
          },
          {
            label: t('header.manageQuotes'),
            route: routes.manageQuotesRoute,
          },
          {
            label: t('header.manageOwnQuotes'),
            route: routes.manageOwnQuotesRoute,
          },
          {
            label: t('header.manageCustomers'),
            route: routes.manageCustomersRoute,
          },
          {
            label: t('header.manageSales'),
            route: routes.manageSalesRoute,
          },
          {
            label: t('header.manageCalculator'),
            route: routes.calculatorSettingsRoute,
          },
          {
            label: t('header.statistics'),
            route: routes.statisticsRoute,
          },
        ],
      },
      logout: {
        label: t('header.logout'),
        route: routes.logoutRoute,
      },
    }),
    [t]
  );

  const handleCustomerArea = () => {
    showModal({
      showCloseButton: false,
      showConfirmButton: false,
      title: t('header.loginModalTitle'),
      body: <LoginDialog hideModal={hideModal} />,
    });
  };

  // https://stackoverflow.com/questions/60694918/how-to-change-bootstrap-navbar-color-on-scroll-in-react-js
  const [scrolled, setScrolled] = useState(false);
  const navRef = useRef();
  navRef.current = scrolled;
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      if (navRef.current !== isScrolled) {
        setScrolled(isScrolled);
      }
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <LanguageBar />
      <Navbar
        expand="lg"
        variant="light"
        fixed="top"
        className={`${scrolled ? 'navbar-scrolled' : ''}`}
        collapseOnSelect
      >
        <Row>
          <Navbar.Brand className="ml-5">
            <Link to={routes.indexRoute}>
              <img alt="Doktor BOX" src={HeaderLogo} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="p-3" id="basic-navbar-nav">
            <div className="user-menu-wrapper d-md-flex">
              <UserMenu items={userMenu} />
            </div>
            <div className="account-menu-wrapper d-md-flex mr-3">
              {isAuth ? (
                <div className="account-menu-wrapper d-flex">
                  <UserMenu items={accountMenu} />
                </div>
              ) : (
                <>
                  <Button className="customer-area-button" onClick={handleCustomerArea}>
                    {t('header.customerArea')}
                  </Button>
                </>
              )}
            </div>
          </Navbar.Collapse>
        </Row>
      </Navbar>
    </>
  );
}
