import React from 'react';
import { ROLE_ADMINISTRATOR, ROLE_CUSTOMER, ROLE_SUPERVISOR } from '../constants/roles';
import RouteDefinition from '../helpers/Route/RouteDefinition';

/**
 * Export all routes that will be registered through the app.
 */
export const indexRoute = new RouteDefinition({
  path: '/',
  component: React.lazy(() => import('../pages/Index')),
  isPrivate: false,
  register: false,
});

// Showcase pages

export const homeRoute = new RouteDefinition({
  path: '/home',
  isPrivate: false,
  component: React.lazy(() => import('../pages/Home')),
});

export const aboutUsRoute = new RouteDefinition({
  path: '/about-us',
  isPrivate: false,
  component: React.lazy(() => import('../pages/Showcase/AboutUs')),
});

export const contactsRoute = new RouteDefinition({
  path: '/contacts',
  isPrivate: false,
  component: React.lazy(() => import('../pages/Showcase/Contacts')),
});

export const viewSalesRoute = new RouteDefinition({
  path: '/sales',
  isPrivate: false,
  component: React.lazy(() => import('../pages/Showcase/Sales')),
});

export const logoutRoute = new RouteDefinition({
  path: '/user/logout',
  component: React.lazy(() => import('../pages/User/Logout')),
});

export const forgotPasswordRoute = new RouteDefinition({
  path: '/user/forgot-password',
  isPrivate: false,
  component: React.lazy(() => import('../pages/User/ForgotPassword')),
});

export const resetPasswordRoute = new RouteDefinition({
  path: '/user/reset-password',
  isPrivate: false,
  component: React.lazy(() => import('../pages/User/ResetPassword')),
});

export const editProfileRoute = new RouteDefinition({
  path: '/user/edit-profile',
  component: React.lazy(() => import('../pages/User/Profile/Edit/Credentials')),
});

export const manageCasesRoute = new RouteDefinition({
  path: '/case/list',
  component: React.lazy(() => import('../pages/Packaging/ManageCases')),
  roles: ROLE_SUPERVISOR,
});

export const manageTrimsRoute = new RouteDefinition({
  path: '/trim/list',
  component: React.lazy(() => import('../pages/Packaging/ManageTrims')),
  roles: ROLE_SUPERVISOR,
});

export const manageEnnoblesRoute = new RouteDefinition({
  path: '/ennoble/list',
  component: React.lazy(() => import('../pages/Packaging/ManageEnnobles')),
  roles: ROLE_SUPERVISOR,
});

export const manageDieCutsRoute = new RouteDefinition({
  path: '/die-cut/list',
  component: React.lazy(() => import('../pages/Packaging/DieCut/List')),
  roles: ROLE_SUPERVISOR,
});

export const addDieCutRoute = new RouteDefinition({
  path: '/die-cut/add',
  component: React.lazy(() => import('../pages/Packaging/DieCut/Add')),
  roles: ROLE_SUPERVISOR,
});

export const editDieCutRoute = new RouteDefinition({
  path: '/die-cut/:id/edit',
  component: React.lazy(() => import('../pages/Packaging/DieCut/Edit')),
  roles: ROLE_SUPERVISOR,
});

export const sendMassiveEmailRoute = new RouteDefinition({
  path: '/send-massive-email',
  component: React.lazy(() => import('../pages/System/SendMassiveEmail')),
  roles: ROLE_SUPERVISOR,
});

export const statisticsRoute = new RouteDefinition({
  path: '/statistics',
  component: React.lazy(() => import('../pages/System/Statistics')),
  roles: ROLE_SUPERVISOR,
});

export const editPackagingItemRoute = new RouteDefinition({
  path: '/packaging-item/:id/edit',
  component: React.lazy(() => import('../pages/Packaging/PackagingItem/Edit')),
  roles: ROLE_SUPERVISOR,
});

/**
 * Packaging
 */

export const createPackagingRoute = new RouteDefinition({
  path: '/create-packaging',
  component: React.lazy(() => import('../pages/Packaging/Create')),
  isPrivate: false,
});

export const confirmedPackagingRoute = new RouteDefinition({
  path: '/confirmed-packaging/:id',
  component: React.lazy(() => import('../pages/Packaging/Confirmed')),
  isPrivate: false,
});

export const manageQuotesRoute = new RouteDefinition({
  path: '/packaging/list',
  component: React.lazy(() => import('../pages/Packaging/Manage')),
  roles: ROLE_SUPERVISOR,
  exact: false,
});

export const manageOwnQuotesRoute = new RouteDefinition({
  path: '/packaging/own',
  component: React.lazy(() => import('../pages/Packaging/ManageOwn')),
  roles: ROLE_CUSTOMER,
  exact: false,
});

export const viewPackagingRoute = new RouteDefinition({
  path: '/packaging/:id',
  component: React.lazy(() => import('../pages/Packaging/View')),
  roles: [ROLE_SUPERVISOR, ROLE_CUSTOMER],
});

export const editPackagingRoute = new RouteDefinition({
  path: '/packaging/:id/edit',
  component: React.lazy(() => import('../pages/Packaging/Edit')),
  roles: [ROLE_SUPERVISOR, ROLE_CUSTOMER],
});

export const deletePackagingRoute = new RouteDefinition({
  path: '/packaging/:id/delete',
  component: React.lazy(() => import('../pages/Packaging/Delete')),
  roles: ROLE_SUPERVISOR,
});

export const manageCustomersRoute = new RouteDefinition({
  path: '/customer/list',
  component: React.lazy(() => import('../pages/User/ListCustomers')),
  roles: ROLE_SUPERVISOR,
});

/**
 * Admin
 */

export const createAdministrationRoute = new RouteDefinition({
  path: '/administration/create',
  component: React.lazy(() => import('../pages/User/Administration/Create')),
  roles: ROLE_ADMINISTRATOR,
});

export const manageSalesRoute = new RouteDefinition({
  path: '/sale/list',
  component: React.lazy(() => import('../pages/System/Sale/List')),
  roles: ROLE_SUPERVISOR,
});

export const addSaleRoute = new RouteDefinition({
  path: '/sale/add',
  component: React.lazy(() => import('../pages/System/Sale/Add')),
  roles: ROLE_SUPERVISOR,
});

export const editSaleRoute = new RouteDefinition({
  path: '/sale/:id/edit',
  component: React.lazy(() => import('../pages/System/Sale/Edit')),
  roles: ROLE_SUPERVISOR,
});

export const privacyPolicyRoute = new RouteDefinition({
  path: '/privacy-policy',
  component: React.lazy(() => import('../pages/PrivacyPolicy')),
  isPrivate: false,
});

export const calculatorSettingsRoute = new RouteDefinition({
  path: '/calculator',
  component: React.lazy(() => import('../pages/CalculatorSettings')),
  roles: ROLE_SUPERVISOR,
});
