import React from 'react';
import useSpinner from '../../hooks/useSpinner';
import Spinner from '../Spinner';

/**
 * A component that renders a full page spinner
 */
export default function GlobalSpinner() {
  const { status } = useSpinner();
  return (
    <>
      {status.show && (
        <Spinner message={status.message} containerClass="global-spinner-container" />
      )}
    </>
  );
}
