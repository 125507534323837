import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from '../../components/Page/components/PageContent';
import log from '../../helpers/Logger';

/**
 * A component that renders the access denied page
 */
export default function AccessDenied() {
  const { t } = useTranslation();

  useEffect(() => {
    log.warn('Insufficient permissions');
  }, []);

  return (
    <PageContent title={t('accessDenied.title')}>
      <h3>{t('accessDenied.description')}</h3>
    </PageContent>
  );
}
