/**
 * Check if variable is an object
 *
 * @param {Object} obj
 * @return {Boolean}
 */
export const isObject = (obj) => {
  const type = typeof obj;
  return (type === 'function' || type === 'object') && !!obj;
};

/**
 * Get key of path (dot notation)
 *
 * @param {String} path
 * @returns {Array<String>}
 */
export const getKeyPath = (path) => (Array.isArray(path) ? path : path.split('.'));

/**
 * Get property value from an object ar an array using dot notation
 *
 * @param {Object} object
 * @param {String} path
 * @param {any} defaultValue
 */
export const getValue = (object, path, defaultValue) => {
  if (!path) return null;
  const pathArray = Array.isArray(path) ? path : path.split('.').filter((key) => key);
  const pathArrayFlat = pathArray.flatMap((part) =>
    typeof part === 'string' ? part.split('.') : part
  );

  const value = pathArrayFlat.reduce((obj, key) => obj && obj[key], object);
  return value !== undefined ? value : defaultValue;
};

/**
 * Remove the specified values from object, recursively
 *
 * @param {Object} obj The object
 * @param {Array<any>} defaults The values to remove.
 * @returns {Object} A new object with removed values
 */
export const removeTypes = (obj, defaults = [undefined, null, '']) => {
  if (!defaults.length) return obj;
  if (defaults.includes(obj)) return undefined;

  if (Array.isArray(obj))
    return obj
      .map((v) => (v && typeof v === 'object' ? removeTypes(v, defaults) : v))
      .filter((v) => !defaults.includes(v));

  return Object.entries(obj).length
    ? Object.entries(obj)
        .map(([k, v]) => [k, v && typeof v === 'object' ? removeTypes(v, defaults) : v])
        .reduce((a, [k, v]) => (defaults.includes(v) ? a : { ...a, [k]: v }), {})
    : obj;
};

/**
 * Replace values in object, recursively
 */
export const replaceValues = (obj, needle, value) => {
  if (Array.isArray(obj))
    return obj.map((v) => (v && typeof v === 'object' ? replaceValues(v, needle, value) : v));

  return Object.entries(obj).length
    ? Object.entries(obj)
        .map(([k, v]) => [k, v && typeof v === 'object' ? replaceValues(v, needle, value) : v])
        .reduce((a, [k, v]) => ({ ...a, [k]: v === needle ? value : v }), {})
    : obj;
};

/**
 * Convert boolean strings to boolean value
 *
 * @param {string} value
 * @returns {boolean}
 */
export const stringToBool = (value) => {
  if (typeof value !== 'string') return value;

  switch (value.toLowerCase()) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return value;
  }
};

/**
 * Get an array of object keys with dot notation
 *
 * @param {Object} obj
 * @returns {Array<String>}
 */
export const getKeys = (obj) => {
  const keys = [];

  const walk = (o, parent = null) => {
    for (const k of Object.keys(o)) {
      const current = parent ? `${parent}.${k}` : k;

      // TODO: getKeys doesn't loop through arrays
      if (!Array.isArray(o[k]) && isObject(o[k])) {
        walk(o[k], current);
      } else {
        keys.push(current);
      }
    }
  };

  walk(obj);

  return keys;
};

/**
 * Set value on object using dot notation
 *
 * @param {Object} obj
 * @param {String} path
 * @param {any} value
 */
export const setValue = (obj, path, value) =>
  getKeyPath(path).reduceRight(
    (v, k, i, ks) => ({ ...getValue(obj, ks.slice(0, i)), [k]: v }),
    value
  );

/**
 * Merge values of the leftObject with the rightObject.
 * This method returns a new Object.
 *
 * @param {Object} leftObject The object to fetch keys to copy
 * @param {Object} rightObject The object to get values for the keys
 * @param {any} defaultValue The default value to set if key is not found in the rightObject
 * @param {Array<String>} overrideValues The values to override on the right object
 * @return {Object}
 */
export const mergeLeft = (leftObject, rightObject, defaultValue = '', overrideValues = [null]) => {
  let res = {};
  for (const k of getKeys(leftObject)) {
    const rightValue = getValue(rightObject, k, undefined);
    if (rightValue === undefined || overrideValues.includes(rightValue)) {
      const leftValue = getValue(leftObject, k, defaultValue);
      res = setValue(res, k, leftValue);
    } else {
      res = setValue(res, k, rightValue);
    }
  }

  return res;
};

/**
 * Performs a deep merge of `source` into `target`.
 * Mutates `target` only but not its objects and arrays.
 *
 * @author inspired by [jhildenbiddle](https://stackoverflow.com/a/48218209).
 */
export const deepMerge = (target, source) => {
  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach((key) => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      // eslint-disable-next-line no-param-reassign
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      // eslint-disable-next-line no-param-reassign
      target[key] = deepMerge({ ...targetValue }, sourceValue);
    } else {
      // eslint-disable-next-line no-param-reassign
      target[key] = sourceValue;
    }
  });

  return target;
};

/**
 * Check if an object is empty
 *
 * @param {object} obj
 */
export const isEmptyObject = (obj) => isObject(obj) && !Object.keys(obj).length;

/**
 * Check if two object are equal (has the same value for each key).
 *
 * NOTE: This doesn't use recursion so it's useful at just the first level of key tree
 * @param {Object} obj1
 * @param {Object} obj2
 * @returns {Boolean}
 */
export const isEqual = (obj1, obj2) => Object.keys(obj1).every((key) => obj1[key] === obj2[key]);
