import * as Yup from 'yup';
import { isEmpty } from '../../helpers/String';

export default function () {
  // eslint-disable-next-line func-names
  Yup.addMethod(Yup.object, 'atLeastOneOf', function (list) {
    return this.test({
      name: 'atLeastOneOf',
      exclusive: true,
      params: { keys: list },
      test: (value) => value == null || list.some((f) => value[f] != null || !isEmpty(value[f])),
    });
  });

  // eslint-disable-next-line func-names
  Yup.addMethod(Yup.object, 'atLeastOneTrue', function (list) {
    return this.test({
      name: 'atLeastOneTrue',
      exclusive: true,
      params: { keys: list },
      test: (value) => list.some((f) => !!value?.[f]),
    });
  });
}
