import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { NavDropdown, Row } from 'react-bootstrap';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import NavLink from '../../../../../NavLink';
import ClickableComponent from '../../../../../ClickableComponent';

/**
 * A component that renders a user menu
 */
export default function UserMenu({ items }) {
  const history = useHistory();
  const [activeMenu, setActiveMenu] = useState();
  const location = useLocation();

  const menuEntries = useMemo(() => Object.entries(items), [items]);
  const hasSubmenu = useMemo(() => menuEntries.some(([, menu]) => !!menu.subMenu), [menuEntries]);
  const isDropdown = useMemo(() => menuEntries.some(([, menu]) => !!menu.dropDown), [menuEntries]);

  const activateWrapperMenu = useCallback((menuItems, currentLocation) => {
    for (const [key, menu] of menuItems) {
      if (!menu.subMenu && !menu.dropDown) {
        if (
          matchPath(currentLocation, {
            path: menu.route.path,
            exact: menu.route.exact,
          })
        ) {
          setActiveMenu(key);
          return;
        }
      } else if (menu.subMenu) {
        for (const item of menu.subMenu) {
          if (
            matchPath(currentLocation, {
              path: item.route.path,
              exact: item.route.exact,
            })
          ) {
            setActiveMenu(key);
            return;
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (!hasSubmenu || !isDropdown) {
      // If the menu doesn't have a submenu, the NavLink will take care of everything
      return;
    }

    activateWrapperMenu(menuEntries, location.pathname);
  }, [activateWrapperMenu, hasSubmenu, isDropdown, location.pathname, menuEntries]);

  const [mainMenu, subMenu] = useMemo(() => {
    const main = [];
    const sub = [];
    // const dropdown = [];

    for (const [key, menu] of menuEntries) {
      if (!menu.subMenu && !menu.dropDown) {
        // Render a simple menu
        main.push(
          <NavLink key={`main-${key}`} to={menu.route} activeClassName="is-active">
            {menu.label}
            {menu.component && <menu.component />}
          </NavLink>
        );

        continue;
      } else if (menu.dropDown) {
        main.push(
          <React.Fragment key={`dropdown-${key}`}>
            <NavDropdown title={menu.label} id="navbarScrollingDropdown">
              {menu.dropDown.map((item) => (
                <NavLink
                  key={item.route.path}
                  to={item.route}
                  activeClassName="is-submenu-active"
                  isDropDown
                >
                  {item.label}
                  {item.component && <item.component />}
                </NavLink>
              ))}
            </NavDropdown>
          </React.Fragment>
        );

        continue;
      }

      main.push(
        <ClickableComponent
          key={`wrapper-${key}`}
          className={activeMenu === key ? 'is-active' : ''}
          onClick={() => history.push(menu.subMenu?.[0]?.route?.path ?? '')}
        >
          {menu.label}
        </ClickableComponent>
      );

      sub.push(
        <React.Fragment key={`collapse-${key}`}>
          {activeMenu === key && (
            <Row noGutters className="submenu-container">
              {menu.subMenu.map((item) => (
                <NavLink key={item.route.path} to={item.route} activeClassName="is-submenu-active">
                  {item.label}
                  {item.component && <item.component />}
                </NavLink>
              ))}
            </Row>
          )}
        </React.Fragment>
      );
    }

    return [main, sub];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, activeMenu, menuEntries]);

  return (
    <>
      {mainMenu}
      {subMenu}
    </>
  );
}

UserMenu.propTypes = {
  items: PropTypes.shape().isRequired,
};
