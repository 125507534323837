import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Form from '../../../../components/Form';
import loginSchema from './validation/loginSchema';
import TextField from '../../../../components/Form/components/TextField';
import Link from '../../../../components/Link';
import { forgotPasswordRoute } from '../../../../routes';
import Button from '../../../../components/Form/components/Button';

export default function LoginForm({ handleSubmit }) {
  const { t } = useTranslation();

  return (
    <Form
      validationSchema={loginSchema}
      initialValues={{ email: '', password: '' }}
      onSubmit={(values, formikBag) => handleSubmit(values, formikBag)}
    >
      {({ isSubmitting }) => (
        <>
          <Row>
            <TextField
              as={Col}
              name="email"
              label={t('loginForm.emailLabel')}
              description={t('loginForm.emailDescription')}
              autoComplete="username"
            />
          </Row>
          <Row>
            <TextField
              type="password"
              as={Col}
              name="password"
              label={t('loginForm.passwordLabel')}
              description={t('loginForm.passwordDescription')}
              autoComplete="current-password"
            />
          </Row>
          <ul>
            <li>
              <Link to={forgotPasswordRoute}>
                <p>{t('loginForm.forgotPasswordLink')}</p>
              </Link>
            </li>
          </ul>
          <Row>
            <Col sm={4}>
              <Button
                type="submit"
                fullWidth
                variant="default"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                {t('loginForm.submit')}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};
