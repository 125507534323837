import React, { useMemo } from 'react';
import { generatePath, Link as ReactRouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import CheckPermissions from '../CheckPermissions';
import log from '../../helpers/Logger';

/**
 * A component that create a link to route
 */
export default function Link({ to: route, routeParams, queryParams, access, children, ...props }) {
  const destinationPath = useMemo(() => {
    // If no params supplied, return the plain route
    if (!routeParams) return route.path;

    try {
      // Fill the path with supplied parameters
      return generatePath(route.path, routeParams);
    } catch (e) {
      log.error('Error compiling url for route', e, { route, routeParams });
      return route.path;
    }
  }, [route, routeParams]);

  return (
    <CheckPermissions
      roles={route.roles}
      permissions={route.permissions}
      auth={route.isPrivate === undefined || route.isPrivate}
      access={access}
    >
      <ReactRouterLink
        to={{
          pathname: destinationPath,
          ...(queryParams ? { search: `?${new URLSearchParams({ ...queryParams })}` } : {}),
        }}
        {...props}
      >
        {children}
      </ReactRouterLink>
    </CheckPermissions>
  );
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.shape({
    permissions: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    roles: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    path: PropTypes.string.isRequired,
    isPrivate: PropTypes.bool,
  }).isRequired,
  routeParams: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  queryParams: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  access: PropTypes.func,
};

Link.defaultProps = {
  access: undefined,
  routeParams: undefined,
  queryParams: undefined,
};
