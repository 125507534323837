/**
 * Sleep for ms
 *
 * @param {number} ms The number of milliseconds to wait
 * @returns {Promise<void>}
 */
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Check if a function is a thenable (a promise)
 *
 * @param {any} obj
 * @returns {boolean}
 */
export const isThenable = (obj) =>
  !!obj && (obj.constructor.name === 'Promise' || obj.constructor.name === 'AsyncFunction');

/**
 * Check if an object is a function
 *
 * @param {any} obj
 * @returns {boolean}
 */
export const isFunction = (obj) => !!obj && typeof obj === 'function';
