import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PageContent from '../../components/Page/components/PageContent';

/**
 * A component that renders the access denied page
 */
export default function Error({ message }) {
  const { t } = useTranslation();
  return (
    <PageContent title={t('generic.error')}>
      <h3>{message || t('network.genericErrorMessage')}</h3>
    </PageContent>
  );
}

Error.propTypes = {
  message: PropTypes.node,
};

Error.defaultProps = {
  message: undefined,
};
