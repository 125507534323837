/**
 * A custom class that extends Error with a data property that fits the API use case.
 */
class APIError extends Error {
  constructor(message, data, statusCode) {
    super(message);

    this.data = data;
    this.statusCode = statusCode;
  }
}

export default APIError;
