import React, { useContext, useMemo } from 'react';
import { Redirect, Route as RouterRoute } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AuthContext } from '../../context/AuthContext';
import { indexRoute } from '../../routes';
import CheckPermissions from '../CheckPermissions';
import AccessDenied from '../../pages/AccessDenied';
import Error from '../../pages/Error';
import useAppStatus from '../../hooks/useAppStatus';
import {
  ERROR_GENERIC,
  ERROR_NOT_FOUND,
  ERROR_PERMISSION_DENIED,
} from '../../constants/appStatusErrors';
import NotFoundContent from '../../pages/NotFound/components';
import log from '../../helpers/Logger';
import { routePropTypes, routeDefaultProps } from './propTypes/route';
import ComponentLoader from '../ComponentLoader';

/**
 * A component that add support to authentication, roles and permissions to react-router-dom's Route
 * // TODO: Should reuse BaseRoute
 */
export default function Route({
  component: Component,
  isPrivate,
  permissions,
  roles,
  access,
  children,
  componentProps,
  accessDeniedComponent,
  fallbackComponent,
  ...rest
}) {
  const { isAuth } = useContext(AuthContext);
  const { isLoaded, error } = useAppStatus();

  const isPrivateRoute = useMemo(
    () =>
      !!(
        (
          isPrivate === undefined || // A route is always a protected route if not specified
          isPrivate || // If a route is protected
          permissions || // If a route requires permissions
          roles || // If a route requires roles
          access
        ) // If a route supply custom access function //
      ),
    [access, isPrivate, permissions, roles]
  );

  return (
    <RouterRoute
      {...rest}
      render={(props) => (
        <ComponentLoader loading={!isLoaded}>
          {() => {
            if (error) {
              log.debug('Rendering error status');
              // An error was set in AppStatusContext. Render the proper page.
              switch (error.code) {
                case ERROR_NOT_FOUND:
                  return <NotFoundContent />;
                case ERROR_PERMISSION_DENIED:
                  return <AccessDenied />;
                case ERROR_GENERIC:
                default:
                  return <Error message={error.message} />;
              }
            }

            if (isPrivateRoute && !isAuth) {
              // Redirect to login page setting from state
              // NOTE: The from state let the router redirect the user once it's logged in to the
              // protected path asked before. However, set the from state only if not coming from
              // logout route otherwise it will disconnect soon after the login.
              return (
                <Redirect
                  to={{
                    pathname: indexRoute.path,
                    // eslint-disable-next-line react/prop-types
                    ...(props.location.pathname !== indexRoute.path && {
                      // eslint-disable-next-line react/prop-types
                      state: { from: props.location },
                    }),
                  }}
                />
              );
            }

            return (
              <React.Suspense fallback={fallbackComponent}>
                <CheckPermissions
                  roles={roles}
                  permissions={permissions}
                  access={access}
                  auth={isPrivateRoute}
                  accessDeniedComponent={accessDeniedComponent}
                  allowedComponent={
                    children ?? React.isValidElement(Component) ? (
                      Component
                    ) : (
                      <Component {...componentProps} />
                    )
                  }
                />
              </React.Suspense>
            );
          }}
        </ComponentLoader>
      )}
    />
  );
}

Route.propTypes = {
  ...routePropTypes,
  children: PropTypes.node,
};

Route.defaultProps = {
  ...routeDefaultProps,
  children: undefined,
};
