import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  FaFacebook,
  FaInstagram,
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaCube,
  FaCommentAlt,
} from 'react-icons/fa';
import Link from '../../../Link';
import {
  homeRoute,
  aboutUsRoute,
  contactsRoute,
  createPackagingRoute,
  privacyPolicyRoute,
} from '../../../../routes';
import SectionHeader from './components/SectionHeader';

import './footer.css';

export default function Footer() {
  const { t, i18n } = useTranslation();

  // Add script for facebook iframe
  useEffect(() => {
    const script = document.createElement('script');

    const language = i18n.language === 'it' ? 'it_IT' : 'en_GB';
    const appId = process.env.REACT_APP_FB_KEY;

    script.src = `https://connect.facebook.net/${language}/sdk.js#xfbml=1&version=v11.0&appId=${appId}&autoLogAppEvents=1`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [i18n.language]);

  return (
    <footer className="footer-container container-fluid">
      <Row className="px-5 py-3 m-xl-3 footer-wrapper justify-content-center">
        <div className="footer-sections-wrapper">
          <div className="mb-3 mb-md-0 ml-md-5 mx-5 footer-section">
            <SectionHeader Icon={FaMapMarkerAlt} title={t('footer.whereWeAre')} />
            <div className="links-wrapper">
              Doktorbox
              <br />
              Via Santa Maria ai Monti, 201/205
              <br />
              80141 - Napoli
              <br />
              P.IVA 08256111215
            </div>
          </div>
          <div className="mb-3 mb-md-0 ml-md-5 mx-5 footer-section">
            <SectionHeader Icon={FaCube} title={t('footer.menu')} />
            <div className="links-wrapper">
              <Link to={homeRoute}>{t('header.home')}</Link>
              <br />
              <Link to={createPackagingRoute}>{t('header.quotes')}</Link>
              <br />
              <Link to={aboutUsRoute}>{t('header.aboutUs')}</Link>
              <br />
              <Link to={contactsRoute}>{t('header.contacts')}</Link>
            </div>
          </div>
          <div className="mb-3 mb-md-0 ml-md-5 mx-5 footer-section">
            <SectionHeader Icon={FaCommentAlt} title={t('footer.social')} />
            <div className="links-wrapper">
              {t('footer.followUs')}
              <br />
              <a
                href="https://www.facebook.com/www.doktorbox.it"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                <FaFacebook size="22px" fill="#FFFFFF" fillOpacity="0.64" />
              </a>
              <a
                href="https://www.instagram.com/doktorbox_packaging/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link ml-3"
              >
                <FaInstagram size="22px" fill="#FFFFFF" fillOpacity="0.64" />
              </a>
            </div>
          </div>
          <div className="mb-3 mb-md-0 ml-md-5 mx-5 footer-section">
            <SectionHeader Icon={FaPhoneAlt} title={t('footer.support')} />
            <div className="links-wrapper">
              <span>+39 081 751 3202</span>
              <br />
              <span>info@doktorbox.it</span>
            </div>
          </div>
          <div className="mt-3 mt-xl-0 ml-md-5 mx-5 fb-frame-wrapper">
            <div
              className="fb-page"
              data-href="https://www.facebook.com/www.doktorbox.it"
              data-tabs="timeline"
              data-width=""
              data-height=""
              data-small-header="false"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="true"
            >
              <blockquote
                cite="https://www.facebook.com/www.doktorbox.it"
                className="fb-xfbml-parse-ignore"
              >
                <a href="https://www.facebook.com/www.doktorbox.it">Doktorbox Packaging</a>
              </blockquote>
            </div>
          </div>
        </div>
      </Row>
      <Row className="p-2 sub-footer-wrapper">
        <div className="m-auto">
          <Link to={privacyPolicyRoute}>{t('footer.privacyPolicy')}</Link>
        </div>
      </Row>
      <a href="https://www.linkedin.com/in/gaetano-fabozzo-0999b513a/" className="d-none">
        Powered by
      </a>
    </footer>
  );
}
