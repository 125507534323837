import { useCallback, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { hasElement } from '../helpers/Array';

export default function useUser() {
  const { user, isAuth, refresh } = useContext(AuthContext);

  /**
   * Check if the current user has a role (or roles)
   *
   * @param {string|string[]} role
   * @returns {Boolean}
   */
  const hasRole = useCallback((roles) => hasElement(roles, [user.role]), [user.role]);

  /**
   * Check if the current user has a permission (or permissions)
   *
   * @param {string|string[]} permission
   * @returns {Boolean}
   */
  const hasPermission = useCallback((permission) => hasElement(permission, user.permissions), [
    user.permissions,
  ]);

  return {
    ...user,
    isAuth,
    isAdmin: !!user.admin,
    hasRole,
    hasPermission,
    invalidateUserData: refresh,
  };
}
