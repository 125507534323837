import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import useValidationSchemaContext from '../../hook/useValidationSchemaContext';
import { fieldDefaultProps, fieldPropTypes } from '../../propTypes/fieldPropTypes';

const TextField = React.forwardRef(
  (
    {
      as,
      md,
      label,
      name,
      type,
      suffix,
      fullWidth,
      inputGroupPrepend,
      inputGroupAppend,
      disabled,
      onChange,
      onBlur,
      textarea,
      rows,
      className,
      placeholder,
      description,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { required, min, max, length } = useValidationSchemaContext(name);

    return (
      <Field name={name}>
        {({ field, form, meta }) => {
          const isValid = !meta.error;
          const isInvalid = meta.touched && !isValid;
          const asFormControl = {
            as: textarea ? 'textarea' : undefined,
            rows: textarea ? rows : undefined,
          };

          return (
            <Form.Group
              as={as}
              md={md}
              controlId={name}
              className={`${className || ''} ${label && required ? 'required' : ''}`.trim()}
            >
              <Form.Label>{label}</Form.Label>
              <InputGroup className={type === 'number' && !fullWidth ? 'input-type-number' : ''}>
                {inputGroupPrepend}
                <Form.Control
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    if (typeof onChange === 'function') {
                      onChange(e);
                    }
                  }}
                  onBlur={(e) => {
                    field.onBlur(e);
                    if (typeof onBlur === 'function') {
                      onBlur(e);
                    }
                  }}
                  type={type}
                  isInvalid={isInvalid}
                  feedback={meta.error}
                  disabled={disabled || form.isSubmitting}
                  maxLength={max ?? length}
                  minLength={min ?? length}
                  min={min}
                  max={max}
                  required={required}
                  placeholder={placeholder}
                  ref={ref}
                  {...asFormControl}
                  {...props}
                />
                {inputGroupAppend}
                {suffix && <span className="ml-3 d-flex text-field-suffix-wrapper">{suffix}</span>}
                <Form.Control.Feedback type="invalid">{t(meta.error)}</Form.Control.Feedback>
              </InputGroup>
              {description && <p className="description">{description}</p>}
            </Form.Group>
          );
        }}
      </Field>
    );
  }
);

TextField.propTypes = {
  ...fieldPropTypes,
  description: PropTypes.string,
  suffix: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

TextField.defaultProps = {
  ...fieldDefaultProps,
  type: 'text',
  inputGroupPrepend: null,
  inputGroupAppend: null,
  disabled: false,
  fullWidth: false,
  required: false,
  textarea: false,
  description: '',
  rows: 3,
  placeholder: null,
  suffix: '',
};

export default TextField;
