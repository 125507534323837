import React, { useState } from 'react';
import { Spinner, Button as BootstrapButton } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './style/button.css';

export default function Button({
  onClick,
  spinner,
  disabled,
  fullWidth: block,
  loading: propLoading,
  children,
  icon,
  ...props
}) {
  const [loading, setLoading] = useState(false);

  return (
    <BootstrapButton
      block={block}
      {...props}
      disabled={!!disabled || (spinner && loading)}
      onClick={(e) => {
        if (!spinner || typeof propLoading !== 'undefined') {
          // The button doesn't use spinner or the loading is controller
          // from the outside
          onClick(e);
          return;
        }

        // Set loading state
        setLoading(true);

        Promise.resolve(onClick(e)).finally(() => setLoading(false));
      }}
    >
      {spinner && ((typeof propLoading !== 'undefined' && propLoading) || loading) && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          className="btn-spinner"
        />
      )}
      {icon && <span className={`button-icon ${icon}`} />}
      {children}
    </BootstrapButton>
  );
}

Button.propTypes = {
  onClick: PropTypes.func,
  spinner: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
};

Button.defaultProps = {
  onClick: () => {},
  spinner: true,
  disabled: false,
  fullWidth: false,
  loading: undefined,
  icon: '',
};
