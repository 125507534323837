import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAppStatus from '../../../../hooks/useAppStatus';
import ComponentLoader from '../../../ComponentLoader';

/**
 * A baseRoute component that renders a spinner waiting for app to be loaded
 */
export default function BaseRoute({ component: Component, children, ...props }) {
  const { isLoaded } = useAppStatus();

  return (
    <Route
      {...props}
      render={(renderProps) => (
        <ComponentLoader loading={!isLoaded}>
          {children ?? <Component {...renderProps} />}
        </ComponentLoader>
      )}
    />
  );
}

BaseRoute.propTypes = {
  component: PropTypes.node,
  children: PropTypes.node,
};

BaseRoute.defaultProps = {
  component: null,
  children: null,
};
