import { useContext } from 'react';
import { SpinnerContext } from '../context/SpinnerContext';

export default function useSpinner() {
  const { status, setStatus } = useContext(SpinnerContext);

  return {
    status,
    showSpinner: (message) => setStatus({ show: true, message }),
    hideSpinner: () => setStatus({ show: false, message: null }),
  };
}
