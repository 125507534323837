import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useAPI from '../../hooks/useAPI';
import log from '../../helpers/Logger';
import useAppStatus from '../../hooks/useAppStatus';
import { ROLE_ADMINISTRATOR } from '../../constants/roles';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuth, setAuth] = useState(true);
  const [user, setUser] = useState({});
  const [error, setError] = useState(false);
  const { setLoaded } = useAppStatus();

  const { login, logout, getUser } = useAPI();

  const setUserAuth = () => {
    setLoaded(false);
    setAuth(true);
  };

  const handleLogin = (email, password) =>
    login(email, password).then((data) => {
      setUserAuth();
      return data;
    });

  const handleLogout = () =>
    logout().then(() => {
      setLoaded(false);
      setAuth(false);
    });

  const fetchUser = () => {
    getUser()
      .then((userData) =>
        setUser({
          ...userData,
          admin: userData.role === ROLE_ADMINISTRATOR,
        })
      )
      .catch((e) => {
        log.error('Failed to retrieve user data', e);
        setAuth(false);
        if (e.statusCode === 401) localStorage.remove('token');
        else if (e.statusCode !== 401) setError(true);
      })
      .finally(() => setLoaded(true));
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setAuth(false);
      setLoaded(true);
    } else fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  return (
    <AuthContext.Provider
      value={{
        error,
        isAuth,
        user,
        login: handleLogin,
        logout: handleLogout,
        setUserAuth,
        refresh: fetchUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
