import React, { createContext } from 'react';
import PropTypes from 'prop-types';

const ValidationSchemaContext = createContext({ schema: undefined });

const ValidationSchemaProvider = ({ schema, children }) => {
  return (
    <ValidationSchemaContext.Provider value={{ schema }}>
      {children}
    </ValidationSchemaContext.Provider>
  );
};

export { ValidationSchemaContext, ValidationSchemaProvider };

ValidationSchemaProvider.propTypes = {
  children: PropTypes.node.isRequired,
  schema: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

ValidationSchemaProvider.defaultProps = {
  schema: undefined,
};
