import React from 'react';
import PropTypes from 'prop-types';
// import { Container } from 'react-bootstrap';
import Header from './components/Header';
import Footer from './components/Footer';

import './style/page.css';

export default function Page({ footer, header, children }) {
  return (
    <>
      {header && <Header />}
      {children}
      {footer && <Footer />}
    </>
  );
}

Page.propTypes = {
  children: PropTypes.node,
  footer: PropTypes.bool,
  header: PropTypes.bool,
};

Page.defaultProps = {
  footer: true,
  header: true,
  children: null,
};
