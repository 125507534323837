import { useContext } from 'react';
import { StatusMessageContext } from '../components/Page/context/StatusMessageContext';

export default function useStatusMessage() {
  const { setStatus, ...status } = useContext(StatusMessageContext);

  return {
    status,
    addMessage: (message, type = 'success') =>
      setStatus({
        ...status,
        message,
        type,
        show: true,
      }),
    hideMessage: () => setStatus({ ...status, show: false }),
  };
}
