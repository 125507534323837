import React, { useCallback } from 'react';
import { Formik, yupToFormErrors, validateYupSchema } from 'formik';
import { Form as BootstrapForm } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ValidationSchemaProvider } from './context/ValidationSchemaContext';
import ErrorFocus from './components/ErrorFocus';
import { removeTypes, replaceValues, setValue } from '../../helpers/Object';
import log from '../../helpers/Logger';

import './style/form.css';

export default function Form({
  children,
  onSubmit,
  removeEmptyValues,
  validationSchema,
  validateOnChange,
  validateOnBlur,
  ...props
}) {
  const handleValidation = useCallback((values, schema) => {
    if (!schema) {
      return {};
    }

    return validateYupSchema(values, schema, false, values)
      .then(() => ({}))
      .catch((e) => {
        // Define our custom errors logic since yupToFormErrors doesn't parse
        // error thrown by Yup's addMethod.
        const customErrors =
          e.inner?.filter(
            (error) => error.type === 'atLeastOneOf' || error.type === 'atLeastOneTrue'
          ) ?? [];
        let errors = yupToFormErrors(e);

        for (const customError of customErrors) {
          let path = customError.params?.path ?? '';
          if (path === 'this') {
            path = '';
          }

          for (const key of customError.params?.keys ?? []) {
            const computedPath = path ? `${path}.${key}` : key;
            errors = setValue(errors, computedPath, customError.message);
          }
        }

        return errors;
      });
  }, []);

  return (
    <Formik
      validationSchema={validationSchema}
      validate={(values) => handleValidation(values, validationSchema)}
      validateOnBlur={validateOnBlur}
      validateOnChange={validateOnChange}
      onSubmit={(values, formikBag) => {
        if (removeEmptyValues) {
          log.debug('Removing empty string from form values');
          return onSubmit(removeTypes(values, ['']), formikBag);
        }
        return onSubmit(replaceValues(values, '', null), formikBag);
      }}
      {...props}
    >
      {(formikProps) => (
        <BootstrapForm onSubmit={formikProps.handleSubmit} noValidate>
          <ValidationSchemaProvider schema={validationSchema}>
            <ErrorFocus />
            {children?.(formikProps) ?? children}
          </ValidationSchemaProvider>
        </BootstrapForm>
      )}
    </Formik>
  );
}

Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  removeEmptyValues: PropTypes.bool,
  validationSchema: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  validateOnChange: PropTypes.bool,
  validateOnBlur: PropTypes.bool,
};

Form.defaultProps = {
  removeEmptyValues: false,
  validationSchema: undefined,
  validateOnChange: true,
  validateOnBlur: false,
};
