/**
 * Check if an element (needle) is contained in an array (haystack)
 *
 * @param {(T|Array.<T>)} needle The element to search
 * @param {Array.<T>} haystack The array to search in
 */
export const hasElement = (needle, haystack) => {
  if (Array.isArray(needle)) {
    return [haystack, needle].reduce((a, c) => a?.filter((i) => c?.includes(i)))?.length > 0;
  }

  return haystack?.includes(needle);
};

export const chunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr];
  if (chunkSize <= 0) return cache;
  while (tmp.length) cache.push(tmp.splice(0, chunkSize));
  return cache;
};
