import React, { useMemo } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { generatePath, NavLink as ReactRouterNavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import CheckPermissions from '../CheckPermissions';
import log from '../../helpers/Logger';

/**
 * A component that create a NavLink to route
 */
export default function NavLink({
  to: route,
  routeParams,
  activeClassName,
  checkActive,
  children,
  isDropDown,
  ...props
}) {
  const destinationPath = useMemo(() => {
    // If no params supplied, return the plain route
    if (!routeParams) return route.path;

    try {
      // Fill the path with supplied parameters
      return generatePath(route.path, routeParams);
    } catch (e) {
      log.error('Error compiling url for route', e, { route, routeParams });
      return route.path;
    }
  }, [route, routeParams]);

  return (
    <CheckPermissions
      roles={route.roles}
      permissions={route.permissions}
      auth={route.isPrivate === undefined || route.isPrivate}
      access={route.access}
    >
      {isDropDown ? (
        <NavDropdown.Item
          as={ReactRouterNavLink}
          to={destinationPath}
          href={destinationPath}
          activeClassName={activeClassName}
          {...props}
          isActive={checkActive}
        >
          {children}
        </NavDropdown.Item>
      ) : (
        <Nav.Link
          as={ReactRouterNavLink}
          to={destinationPath}
          href={destinationPath}
          activeClassName={activeClassName}
          {...props}
          isActive={checkActive}
        >
          {children}
        </Nav.Link>
      )}
    </CheckPermissions>
  );
}

NavLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.shape({
    permissions: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    roles: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    path: PropTypes.string.isRequired,
    isPrivate: PropTypes.bool,
  }).isRequired,
  activeClassName: PropTypes.string,
  routeParams: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  checkActive: PropTypes.func,
  isDropDown: PropTypes.bool,
};

NavLink.defaultProps = {
  routeParams: undefined,
  activeClassName: 'is-active',
  checkActive: null,
  isDropDown: false,
};
