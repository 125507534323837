import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import ModalDialog from '../../components/ModalDialog';

const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const location = useLocation();
  const [data, setData] = useState({ show: false });

  useEffect(() => {
    setData({ show: false });
  }, [location]);

  return (
    <ModalContext.Provider
      value={{
        show: data.show,
        data,
        setData,
      }}
    >
      <>
        <ModalDialog {...data} />
        {children}
      </>
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ModalContext, ModalProvider };
