import React from 'react';
import { useTranslation } from 'react-i18next';
import ClickableComponent from '../ClickableComponent';
import itFlag from '../../assets/images/languages/it.jpg';
import ukFlag from '../../assets/images/languages/uk.jpg';

import './style/language-switcher.css';

export default function LanguageSwitcher() {
  const { i18n, t } = useTranslation();

  const changeLanguageHandler = (language) => {
    localStorage.setItem('language', language);
    i18n.changeLanguage(language);
  };

  return (
    <div className="language-switcher-wrapper">
      <ClickableComponent
        onClick={() => changeLanguageHandler('it')}
        disabled={i18n.language === 'it'}
      >
        <img
          className={`language-switcher ${i18n.language === 'it' ? 'active' : ''}`}
          src={itFlag}
          alt={t('languages.italian')}
        />
      </ClickableComponent>
      <ClickableComponent
        onClick={() => changeLanguageHandler('en')}
        disabled={i18n.language === 'en'}
      >
        <img
          className={`language-switcher ${i18n.language === 'en' ? 'active' : ''}`}
          src={ukFlag}
          alt={t('languages.english')}
        />
      </ClickableComponent>
    </div>
  );
}
