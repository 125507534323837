import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../Spinner';
import AccessDenied from '../../../pages/AccessDenied';

export const routePropTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.any]),
  componentProps: PropTypes.oneOfType([PropTypes.object]),
  roles: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  permissions: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  access: PropTypes.func,
  isPrivate: PropTypes.bool,
  fallbackComponent: PropTypes.node,
  accessDeniedComponent: PropTypes.node,
};

export const routeDefaultProps = {
  component: undefined,
  componentProps: {},
  isPrivate: true,
  roles: undefined,
  permissions: undefined,
  access: undefined,
  fallbackComponent: <Spinner />,
  accessDeniedComponent: <AccessDenied />,
};
