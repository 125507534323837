import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export default function () {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      lng: localStorage.getItem('language') || 'it',
      fallbackLng: null,
      supportedLngs: ['en', 'it'],
      debug: false,
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: true,
      },
    });
}
