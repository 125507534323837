import { lazy } from 'react';

// TODO: With RouteDefinition, review the way Route checks for access, etc
export default class RouteDefinition {
  constructor({
    path,
    component,
    exact = true,
    isPrivate = true,
    access,
    roles,
    permissions,
    layout,
    register = true,
    componentProps,
  }) {
    this.path = path;
    this.component = component;
    this.exact = exact;
    this.isPrivate = isPrivate;
    this.access = access;
    this.roles = roles;
    this.layout =
      layout !== undefined
        ? layout
        : {
            id: 'default',
            component: lazy(() => import('../../components/Page')),
          };
    this.permissions = permissions;
    this.register = register;
    this.componentProps = componentProps;
  }
}
