import { useEffect } from 'react';
import { useFormikContext } from 'formik';

/**
 * Automatically scroll form to the first errored field
 */
export default function ErrorFocus() {
  const { isSubmitting, isValidating, errors } = useFormikContext();

  useEffect(() => {
    const keys = Object.keys(errors);

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const errorElement =
        document.querySelector(`label[for="${keys[0]}"]`) ??
        document.querySelector(`input[name="${keys[0]}"]`);

      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [isSubmitting, isValidating, errors]);

  return null;
}
