import React from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from '../../../components/Page/components/PageContent';

export default function NotFoundContent() {
  const { t } = useTranslation();
  return (
    <PageContent title={t('pageNotFound.title')}>
      <h3>{t('pageNotFound.content')}</h3>
    </PageContent>
  );
}
