/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import log from '../../helpers/Logger';
import { isDevelopment } from '../../helpers/Environment';
import { getWithExpiry, setWithExpiry } from '../../helpers/LocalStorage';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    log.error(`ErrorBoundary: ${error?.message}`, {
      stack: isDevelopment() ? errorInfo?.componentStack : error?.stack,
    });
  }

  render() {
    if (this.state.hasError) {
      const message = this.state.error?.message;
      if (
        /Loading chunk [\d]+ failed/.test(message) ||
        /Loading CSS chunk [\d]+ failed/.test(message)
      ) {
        log.warn(`ErrorBoundary: Reloading due to missing chunk. A new version is available.`);
        if (!getWithExpiry('chunk_failed')) {
          setWithExpiry('chunk_failed', true, 10000);
          window.location.reload();
        }
      } else {
        return <h3>Errore durante l&apos;elaborazione della richiesta. Riprovare più tardi.</h3>;
      }
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};
