import React from 'react';
import { Alert } from 'react-bootstrap';
import useStatusMessage from '../../../../hooks/useStatusMessage';

export default function StatusMessage() {
  const {
    status: { show, message, type },
    hideMessage,
  } = useStatusMessage();

  return (
    show && (
      <Alert
        className={`alert-${type} react-alert`}
        key="statusMessage"
        variant={type}
        onClose={hideMessage}
        dismissible
      >
        {message}
      </Alert>
    )
  );
}
