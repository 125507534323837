import './polyfill';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import Root from './components/Root';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './components/ErrorBoundary';
import initLoader from './loaders';

initLoader();

ReactDOM.render(
  <ErrorBoundary>
    <Root />
  </ErrorBoundary>,
  document.getElementById('app')
);

serviceWorker.unregister();
