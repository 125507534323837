import { useCallback, useContext } from 'react';
import { ModalContext } from '../context/ModalContext';
import { isFunction } from '../helpers/Promise';

export default function useModal() {
  const { setData, show } = useContext(ModalContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hideModal = useCallback(() => setData({ show: false }), []);

  return {
    showModal: (data) => {
      const { onClose, onConfirm, ...rest } = data;

      setData({
        show: true,
        handleClose: () => {
          hideModal();
          if (isFunction(onClose)) {
            onClose();
          }
        },
        handleConfirm: () => {
          hideModal();
          if (isFunction(onConfirm)) {
            onConfirm();
          }
        },
        ...rest,
      });
    },
    hideModal,
    show,
  };
}
