import React from 'react';
import PropTypes from 'prop-types';
import { Spinner as BootstrapSpinner } from 'react-bootstrap';
import LoaderSrc from '../../assets/images/loader.gif';

/**
 * A component that renders a full page spinner
 */
export default function Spinner({ message, useDefault, ...props }) {
  return useDefault ? (
    <div>
      <div className="spinner-wrapper">
        <img className="spinner-image" src={LoaderSrc} alt="loader" />
      </div>
      {message && (
        <div className="spinner-message">
          {React.isValidElement(message) ? message : <p>{message ?? ''}</p>}
        </div>
      )}
    </div>
  ) : (
    <BootstrapSpinner animation="border" role="status" {...props} />
  );
}

Spinner.propTypes = {
  useDefault: PropTypes.bool,
  message: PropTypes.string,
};

Spinner.defaultProps = {
  useDefault: true,
  message: undefined,
};
