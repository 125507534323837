import React from 'react';
import { FaFacebook, FaInstagram, FaPhoneAlt } from 'react-icons/fa';
import LanguageSwitcher from '../../../../../LanguageSwitcher';

import './style/language-bar.css';

export default function LanguageBar() {
  return (
    <div className="language-bar-wrapper float-right d-flex justify-content-end p-2">
      <div className="social-links-wrapper">
        <a
          href="https://www.facebook.com/www.doktorbox.it"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          <FaFacebook size="18px" fill="#FFFFFF" fillOpacity="0.64" />
        </a>
        <a
          href="https://www.instagram.com/doktorbox_packaging/"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          <FaInstagram size="18px" fill="#FFFFFF" fillOpacity="0.64" />
        </a>
        <a href="tel:+390817513202" className="ml-1 phone-wrapper">
          <FaPhoneAlt size="14px" fill="#FFFFFF" fillOpacity="0.64" />
          <strong className="ml-1">+39 081 751 3202</strong>
        </a>
      </div>
      <LanguageSwitcher />
    </div>
  );
}
