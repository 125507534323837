import * as logger from 'loglevel';

const log = {
  ...logger,
  error: (...msg) => {
    logger.error(msg);
  },
  warn: (...msg) => {
    logger.warn(msg);
  },
};

export default log;
