import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { isEmpty } from '../../../../../../helpers/String';

export default function PageTitle({ title, description, showTitle }) {
  useEffect(() => {
    if (!isEmpty(title)) {
      global.document.title = title.concat(' | ', 'DoktorBox');
    }
  }, [title]);

  return (
    <>
      {(title || description) && showTitle && (
        <div className="mt-header">
          <Col md="12" className="page-title">
            {title && <h1>{title}</h1>}
            {description && <p>{description}</p>}
          </Col>
        </div>
      )}
    </>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  showTitle: PropTypes.bool,
};

PageTitle.defaultProps = {
  title: null,
  description: null,
  showTitle: true,
};
