import log from '../helpers/Logger';
import yupLoader from './Yup';
import loggerLoader from './Logger';
import i18nLoader from './i18n';

export default function init() {
  yupLoader();
  loggerLoader();
  i18nLoader();
  log.debug('All loaders run');
}
