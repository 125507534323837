import PropTypes from 'prop-types';
import useUser from '../../hooks/useUser';

/**
 * A component that give access to children/component if criteria are met.
 */
export default function CheckPermissions({
  roles,
  permissions,
  access,
  auth,
  accessDeniedComponent: AccessDeniedComponent,
  allowedComponent: AllowedComponent,
  children,
}) {
  const user = useUser();
  const { hasRole, hasPermission, isAdmin, isAuth } = user;

  const isAllowed =
    isAdmin || // The user is an admin
    ((!auth || isAuth) && // The authentication is not needed or needed and user is auth
      (!(typeof access === 'function') || access(user)) && // an access function is supplied
      (!roles || hasRole(roles)) && // no roles or role met
      (!permissions || hasPermission(permissions))); // no permission or permission met

  if (isAllowed) {
    return AllowedComponent ?? children;
  }

  return AccessDeniedComponent ?? null;
}

CheckPermissions.propTypes = {
  roles: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  permissions: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  access: PropTypes.func,
  auth: PropTypes.bool,
  accessDeniedComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  allowedComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.node,
};

CheckPermissions.defaultProps = {
  accessDeniedComponent: null,
  allowedComponent: null,
  auth: true,
  access: undefined,
  roles: undefined,
  permissions: undefined,
};
