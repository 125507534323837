import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from '../ScrollToTop';
import { AuthProvider } from '../../context/AuthContext';
import { SpinnerProvider } from '../../context/SpinnerContext';
import GlobalSpinner from '../GlobalSpinner';
import StatusMessageProvider from '../Page/context/StatusMessageContext';
import { AppStatusProvider } from '../../context/AppStatusContext';
import { ModalProvider } from '../../context/ModalContext';
import Router from '../Router';

import './style/root.css';

export default function Root() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <SpinnerProvider>
        <GlobalSpinner />
        <AppStatusProvider>
          <AuthProvider>
            <StatusMessageProvider>
              <ModalProvider>
                <Router />
              </ModalProvider>
            </StatusMessageProvider>
          </AuthProvider>
        </AppStatusProvider>
      </SpinnerProvider>
    </BrowserRouter>
  );
}
