import React, { useEffect } from 'react';
import Page from '../../components/Page';
import log from '../../helpers/Logger';
import NotFoundContent from './components';

/**
 * A component that renders the 404 page
 */
export default function NotFound() {
  useEffect(() => {
    log.warn('Resource not found');
  }, []);

  return (
    <Page>
      <NotFoundContent />
    </Page>
  );
}
