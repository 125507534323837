import { useContext } from 'react';
import { AppStatusContext } from '../context/AppStatusContext';
import log from '../helpers/Logger';

export default function useAppStatus() {
  const appStatus = useContext(AppStatusContext);

  return {
    ...appStatus,
    setError: (error, message) => {
      log.error('Setting AppStatus to error', null, error);
      appStatus.setError({ code: error, message });
    },
  };
}
