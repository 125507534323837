import React, { useState, useEffect, createContext } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from '../../../../helpers/String';
import { sleep } from '../../../../helpers/Promise';

export const StatusMessageContext = createContext();

const StatusMessageProvider = ({ children }) => {
  const [status, setStatus] = useState({ show: false, message: null, type: 'success' });
  const [shouldHide, setShouldHide] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (status.show && !isEmpty(status.message) && shouldHide) {
      setStatus({
        ...status,
        show: false,
      });
      setShouldHide(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (status.show && !isEmpty(status.message)) {
      window.scrollTo(0, 0);
    }

    sleep(500).then(() => setShouldHide(true));
  }, [status]);

  return (
    <StatusMessageContext.Provider
      value={{
        ...status,
        setStatus,
      }}
    >
      {children}
    </StatusMessageContext.Provider>
  );
};

export default StatusMessageProvider;

StatusMessageProvider.propTypes = {
  children: PropTypes.node,
};

StatusMessageProvider.defaultProps = {
  children: null,
};
