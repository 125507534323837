import React from 'react';
import PropTypes from 'prop-types';

export default function ClickableComponent({ onClick, children, ...props }) {
  return (
    <div role="button" tabIndex={0} onKeyPress={() => null} onClick={onClick} {...props}>
      {children}
    </div>
  );
}

ClickableComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
