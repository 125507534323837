import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ModalDialog = ({
  handleClose,
  handleConfirm,
  showCloseButton,
  showConfirmButton,
  title,
  description,
  closeLabel,
  confirmLabel,
  disabled,
  body,
  footer,
  ...props
}) => (
  <Modal onHide={handleClose} {...props}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {body}
      {description}
    </Modal.Body>
    <Modal.Footer>
      {footer}
      {showCloseButton && (
        <Button
          variant="link"
          onClick={handleClose}
          disabled={disabled}
          className="close-modal-link"
        >
          {closeLabel}
        </Button>
      )}
      {showConfirmButton && (
        <Button variant="secondary" onClick={handleConfirm} disabled={disabled}>
          {confirmLabel}
        </Button>
      )}
    </Modal.Footer>
  </Modal>
);

ModalDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  showCloseButton: PropTypes.bool,
  showConfirmButton: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  closeLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  disabled: PropTypes.bool,
  body: PropTypes.node,
  footer: PropTypes.node,
};

ModalDialog.defaultProps = {
  handleClose: () => null,
  handleConfirm: () => null,
  showCloseButton: true,
  showConfirmButton: true,
  title: '',
  description: '',
  closeLabel: 'Annulla',
  confirmLabel: 'Conferma',
  disabled: false,
  body: null,
  footer: null,
};

export default ModalDialog;
