import React from 'react';
import PropTypes from 'prop-types';

export default function SectionHeader({ Icon, title }) {
  return (
    <div className="mb-2 section-header">
      <Icon size="25px" fill="#9EC8FC" />
      <strong className="ml-2">{title}</strong>
    </div>
  );
}

SectionHeader.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
};
