import PropTypes from 'prop-types';

export const fieldPropTypes = {
  as: PropTypes.elementType,
  md: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  sublabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export const fieldDefaultProps = {
  as: null,
  md: undefined,
  disabled: false,
  className: '',
  label: '',
  sublabel: '',
  placeholder: '',
  onBlur: undefined,
  onChange: undefined,
  description: undefined,
};
