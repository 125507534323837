import { lazy } from 'react';

export default class RoutesWrapper {
  constructor({ routes, wrapper, register = true, layout }) {
    this.routes = routes;
    this.wrapper = wrapper;
    this.register = register;
    this.layout =
      layout !== undefined
        ? layout
        : {
            id: 'default',
            component: lazy(() => import('../../components/Page')),
          };
  }

  get path() {
    return this.routes?.map((route) => route.path);
  }
}
