import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const SpinnerContext = createContext();

const SpinnerProvider = ({ children }) => {
  const [status, setStatus] = useState({ show: false, message: null });

  return (
    <SpinnerContext.Provider
      value={{
        setStatus,
        status,
      }}
    >
      {children}
    </SpinnerContext.Provider>
  );
};

SpinnerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { SpinnerContext, SpinnerProvider };
