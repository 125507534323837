import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LoginForm from '../../../../../../pages/User/components/LoginForm';
import log from '../../../../../../helpers/Logger';
import { AuthContext } from '../../../../../../context/AuthContext';

export default function LoginDialog({ hideModal }) {
  const { t } = useTranslation();
  const { login } = useContext(AuthContext);

  const handleSubmit = useCallback(({ email, password }, { setSubmitting, setErrors }) => {
    login(email, password)
      .then(() => hideModal())
      .catch((e) => {
        setSubmitting(false);
        let errorMessage;
        switch (e.statusCode) {
          case 401:
            errorMessage = t('loginForm.loginErrorCredentials');
            break;
          case 423:
            errorMessage = t('loginForm.loginErrorAccountBlocked');
            break;
          default:
            errorMessage = t('errorGeneric');
            log.error('Generic error during login', e);
        }

        setErrors({ email: errorMessage, password: errorMessage });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoginForm handleSubmit={handleSubmit} />;
}

LoginDialog.propTypes = {
  hideModal: PropTypes.func.isRequired,
};
