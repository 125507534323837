import React from 'react';
import axios from 'axios';
import log from '../helpers/Logger';
import APIError from '../helpers/APIError';

log.debug('Building axios instance');
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY_URL ?? '',
  withCredentials: true,
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    log.debug(
      `${config.method.toUpperCase()}: ${config.baseURL}${config.url} ${
        config.params ? JSON.stringify(config.params) : ''
      } ${config.data ? JSON.stringify(config.data) : ''}`
    );
    if (typeof token !== 'undefined') {
      // Bearer + token built before sending the request
      const bearerToken = `Bearer ${token}`;
      // eslint-disable-next-line no-param-reassign
      config.headers.authorization = bearerToken;
    }
    return config;
  },
  (e) => {
    log.error(e);
    return Promise.reject(e);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (res) => {
    // Log response data
    log.debug('Response:', res.data);
    return res.config.fullResponse ? res : res.data;
  },
  (e) => {
    // TODO: Handle 401
    const statusCode = e.response?.status;
    const errorData = e.response?.data;
    const path = `${e.config?.baseURL}${e.config?.url}`;
    const method = e.config?.method?.toUpperCase();
    if (e?.config?.logError == null || e?.config?.logError)
      log.error(`APIError at ${method} ${path}`, null, { statusCode, errorData });

    return Promise.reject(new APIError(e.message, errorData, statusCode));
  }
);

const useAPI = () => {
  /**
   * User login.
   *
   * @param {*} email
   * @param {*} password
   */

  const login = async (email, password) => {
    try {
      const res = await axiosInstance.post(
        '/user/login',
        {
          email,
          password,
        },
        { fullResponse: true }
      );

      const splittedToken = res.headers.authorization.split(' ')[1];
      localStorage.setItem('token', splittedToken);
      return res.data;
    } catch (e) {
      log.error(e);
      throw e;
    }
  };

  /**
   * Logout API.
   */
  const logout = async () => {
    try {
      const res = await axiosInstance.delete('/user/logout');
      localStorage.removeItem('token');
      return res.data;
    } catch (e) {
      log.error(e);
      throw e;
    }
  };

  /**
   * Get user information data.
   *
   */
  const getUser = () => axiosInstance.get('/user');

  /**
   * Update user credentials
   *
   * @param {*} values
   */
  const editUser = (values) => axiosInstance.patch('/user/credentials', values);

  /**
   * Reset password for the email passed in input.
   *
   * @param {*} email
   */
  const forgotPasswordEmail = (email) => axiosInstance.post(`/user/forgot-password`, { email });

  /**
   * Verify resetPassword token
   *
   * @param {*} token
   */
  const resetPasswordTokenVerify = (token) =>
    axiosInstance.get('/user/reset-password', { params: { token } });

  /**
   * reset user password
   *
   * @param {*} token
   */
  const resetPassword = async (token, password) => {
    try {
      const res = await axiosInstance.post(
        '/user/reset-password',
        { token, password },
        { fullResponse: true }
      );
      const splittedToken = res.headers.authorization.split(' ')[1];
      localStorage.setItem('token', splittedToken);
      return res.data;
    } catch (e) {
      log.error(e);
      throw e;
    }
  };

  const contact = (values) => axiosInstance.post('/system/contact', values);

  const getPackagingItem = (id) => axiosInstance.get(`/packaging-item/${id}`);

  const getPackagingItems = (filters, paginationData) =>
    axiosInstance.get('/packaging-item/list', { params: { ...paginationData, ...filters } });

  const editPackagingItem = (id, values) => axiosInstance.patch(`/packaging-item/${id}`, values);

  const getPackagingOptions = (params) => axiosInstance.get('/packaging-item/options', { params });

  const getDieCut = (id) => axiosInstance.get(`/die-cut/${id}`);

  const getDieCuts = (filters, paginationData) =>
    axiosInstance.get('/die-cut/list', { params: { ...paginationData, ...filters } });

  const createDieCut = (values) => axiosInstance.post('/die-cut', values);

  const editDieCut = (id, values) => axiosInstance.patch(`/die-cut/${id}`, values);

  const deleteDieCut = (id) => axiosInstance.delete(`/die-cut/${id}`);

  const getSale = (id) => axiosInstance.get(`/system/sale/${id}`);

  const getSales = (filters, paginationData) =>
    axiosInstance.get('/system/sale/list', { params: { ...paginationData, ...filters } });

  const createSale = (values) => axiosInstance.post('/system/sale', values);

  const editSale = (id, values) => axiosInstance.patch(`/system/sale/${id}`, values);

  const deleteSale = (id) => axiosInstance.delete(`/system/sale/${id}`);

  const createAdministration = (values) => axiosInstance.post('/user/administration', values);

  const createPackaging = (values) => axiosInstance.post('/packaging', values);

  const createPackagingAuth = (values) => axiosInstance.post('/packaging/auth', values);

  const calculatePrice = (values) => axiosInstance.post('/packaging/calculate-price', values);

  const calculatePriceAuth = (values) =>
    axiosInstance.post('/packaging/calculate-price/auth', values);

  const getPackaging = (id) => axiosInstance.get(`/packaging/${id}`);

  const listPackagings = (filters, paginationData) =>
    axiosInstance.get('/packaging/list', { params: { ...filters, ...paginationData } });

  const editPackaging = (id, values) => axiosInstance.patch(`/packaging/${id}`, values);

  const deletePackaging = (id) => axiosInstance.delete(`/packaging/${id}`);

  const getStatistics = () => axiosInstance.get('/system/statistics');

  const listCustomers = (filters, paginationData) =>
    axiosInstance.get('/user/list/customer', { params: { ...filters, ...paginationData } });

  const editCustomerSale = (id, values) => axiosInstance.patch(`/user/${id}/sale`, values);

  const validateCustomer = (id) => axiosInstance.patch(`/user/${id}/validate`, {});

  const downloadQuote = (id, language = 'it') =>
    axiosInstance.get(`/packaging/${id}/download`, {
      responseType: 'blob',
      fullResponse: true,
      params: { language },
    });

  const getCalculator = () => axiosInstance.get('/system/calculator');

  const saveCalculator = (values) => axiosInstance.post('/system/calculator', values);

  const uploadCalculator = (values) => axiosInstance.post('/system/calculator/upload', values);

  return React.useMemo(
    () => ({
      login,
      logout,
      getUser,
      editUser,
      forgotPasswordEmail,
      resetPasswordTokenVerify,
      resetPassword,
      contact,
      getPackagingItem,
      getPackagingItems,
      editPackagingItem,
      getPackagingOptions,
      getDieCut,
      getDieCuts,
      createDieCut,
      editDieCut,
      deleteDieCut,
      createAdministration,
      createPackaging,
      createPackagingAuth,
      calculatePrice,
      calculatePriceAuth,
      getPackaging,
      listPackagings,
      editPackaging,
      deletePackaging,
      getStatistics,
      getSale,
      getSales,
      createSale,
      editSale,
      deleteSale,
      listCustomers,
      editCustomerSale,
      validateCustomer,
      downloadQuote,
      getCalculator,
      saveCalculator,
      uploadCalculator,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};

export default useAPI;
