import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { StatusMessageContext } from '../../../../context/StatusMessageContext';
import StatusMessage from '../../../StatusMessage';

export default function StatusMessageConsumer() {
  return (
    <StatusMessageContext.Consumer>
      {({ show }) => (
        <>
          {show && (
            <Row>
              <Col md="12">
                <StatusMessage />
              </Col>
            </Row>
          )}
        </>
      )}
    </StatusMessageContext.Consumer>
  );
}
