/**
 * Check if a string is empty.
 * A bunch of white spaces is considered as an empty string.
 *
 * @param {String} string The string to check
 * @returns {Boolean}
 */
// eslint-disable-next-line import/prefer-default-export
export const isEmpty = (string) =>
  !string || (typeof string === 'string' && string.trim().length === 0);

/**
 * Check if a string has a value (could also be empty)
 *
 * @param {String} string The string to check
 * @returns {Boolean}
 */
export const hasValue = (string) => string != null;

/**
 * Convert string to Boolean
 * @param {String} string
 * @param {Boolean} defaultValue
 * @returns {Boolean}
 */
export const toBoolean = (string, defaultValue) =>
  // eslint-disable-next-line eqeqeq
  ((string == 'true' || string == 'false' || string === true || string === false) &&
    JSON.parse(string)) ||
  defaultValue;

/**
 * Capitalize string
 *
 * @param {String} string
 * @returns {String}
 */
export const capitalize = (string) => string?.charAt(0)?.toUpperCase() + string?.slice(1);
