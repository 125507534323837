import { useContext, useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { reach } from 'yup';
import debounce from 'awesome-debounce-promise';
import useConstant from 'use-constant';
import { ValidationSchemaContext } from '../context/ValidationSchemaContext';
import log from '../../../helpers/Logger';
import { getValue } from '../../../helpers/Object';

/**
 * An hook that extract relevant field validation information from the Yup schema associated to
 * the form.
 *
 * @param {String} name The name of the field
 */
export default function useValidationSchemaContext(name) {
  const { schema } = useContext(ValidationSchemaContext);
  const { values } = useFormikContext();
  const [props, setProps] = useState({
    required: false,
    min: undefined,
    max: undefined,
    length: undefined,
  });

  /**
   * Use debounced function to update the props state.
   *
   * NOTE: This is needed as the function is called every time the form values changes.
   * Since the schema walking takes some times, it can lead to laggy input on large forms.
   * Hence, by debouncing and retarding of 300ms, responsiveness seems fine.
   */
  const setPropsDebounced = useConstant(() =>
    debounce((fieldName, formSchema, formValues) => {
      let fieldSchema;

      try {
        // First extract field schema with reach. Passing formValues is fundamental as conditional
        // field schema is extracted only if form values satisfy the required condition.
        fieldSchema = reach(formSchema, fieldName, formValues);

        // Resolve the field schema using resolve and passing in parent values.
        fieldSchema = fieldSchema?.resolve({
          value: getValue(formValues, fieldName),
          parent: formValues,
        });
      } catch (e) {
        // ignore error
        log.debug(e.message);
      }

      const tests = fieldSchema?.tests;
      setProps({
        required: !!tests?.find((test) => test.OPTIONS.name === 'required'),
        min: tests?.find((test) => test.OPTIONS.name === 'min')?.OPTIONS?.params?.min,
        max: tests?.find((test) => test.OPTIONS.name === 'max')?.OPTIONS?.params?.max,
        length: tests?.find((test) => test.OPTIONS.name === 'length')?.OPTIONS?.params?.length,
      });
    }, 300)
  );

  useEffect(() => {
    if (!schema) {
      return;
    }

    setPropsDebounced(name, schema, values);
  }, [name, schema, values, setPropsDebounced]);

  // Return relevant props from schema
  return { ...props };
}
